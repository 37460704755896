import styles from "./my_modal.module.css";
import { ButtonPrimary, ButtonSecondary } from '../button/button';

interface MyModalProps {
    isShown: boolean
    setIsShown: (isShown: boolean)=>void
    hasExitButton: boolean
    children?: JSX.Element
}

const MyModal = ({ isShown, setIsShown, hasExitButton, children }: MyModalProps) => {
    return (
        <dialog open={isShown} className={styles.WaitlistModal}>
            {hasExitButton ? 
                <div className={styles.ModalButtonContainer}>
                    <button className={styles.ModalExitButton} onClick={()=>{setIsShown(false)}}>❌</button>
                </div>
                : null
            }
            {children}
        </dialog>
    );
}

interface MessageModalProps {
    isShown: boolean
    setIsShown: (isShown: boolean)=>void
    message?: JSX.Element
}

export const MessageModal = ({isShown, setIsShown, message}: MessageModalProps) => {
    return (
        <dialog open={isShown} className={styles.WaitlistModal}>
            <div className={styles.ModalButtonContainer}>
                <button className={styles.ModalExitButton} onClick={()=>{setIsShown(false)}}>❌</button>
            </div>
            {message}
        </dialog>
    );
}

interface ConfirmationModalProps {
    isShown: boolean
    setIsShown: (isShown: boolean)=>void
    confirmCB?: ()=>void;
    rejectCB?: ()=>void
    children?: JSX.Element[]
}

export const ConfirmationModal = ({isShown, setIsShown, confirmCB, rejectCB, children}: ConfirmationModalProps) => {

    const onAccept = () => {
        setIsShown(false);
        if(confirmCB) confirmCB();
    }

    const onReject = () => {
        setIsShown(false);
        if(rejectCB) rejectCB();
    }

    const component = () => {
        return (
            <div className={styles.ConfirmationModal}>
                {children}
                <div className={styles.ConfirmationModalButtonContainer}>
                    {confirmCB ? <ButtonPrimary onClick={onAccept}>Yes</ButtonPrimary> : <ButtonPrimary onClick={onAccept}>Ok</ButtonPrimary>}
                    {rejectCB ? <ButtonSecondary onClick={onReject}>No</ButtonSecondary> : null}
                </div>
            </div>
        );
    }

    return (
        <MyModal isShown={isShown} setIsShown={setIsShown} hasExitButton={false} children={component()} />
    );
}

interface ErrorMessageModalProps {
    isShown: boolean
    setIsShown: (isShown: boolean)=>void
    messages?: string[]
}

export const ErrorMessageModal = ({isShown, setIsShown, messages}: ErrorMessageModalProps) => {

    const formatMessage = () => {
        return (
            <div>
                <h2>An Error Has Occured</h2>
                <h3>Please try again in a moment or try reloading the page.</h3>
                <p>If the issue persists, please contact us about it at contact@live-oak-financial.com</p>
                {messages ? <h4>Error message:</h4> : null}
                {messages?.map((message, idx) => {
                    return (
                        <p key={idx}>{message}</p>
                    );
                })}
            </div>
        );
    }

    return (
        <MyModal isShown={isShown} setIsShown={setIsShown} hasExitButton={true} children={formatMessage()} />
    );
}

export default MyModal;
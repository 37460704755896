import styles from "./toggle_switch.module.css";

const ToggleSwitch = ({value, onChange, direction = "vertical"}: {value: number; onChange: (activeIndex: number) => void; direction?: "vertical" | "horizontal";}) => {

    const options = ["Borrow Money", "Build Wealth", "Manage Finances"];

    return (
        <div
            className={`${styles.container} ${
                direction === "horizontal" ? styles.horizontal : styles.vertical
            }`}
        >
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`${styles.option} ${
                        value === index ? styles.active : ""
                    }`}
                    onClick={() => onChange(index)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default ToggleSwitch;
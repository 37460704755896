import "./privacy_policy.css";
import React from 'react';

const EFFECTIVE_DATE = "September 18, 2024"
const EMAIL_CONTACT: string = "contact@live-oak-financial.com";

const PrivacyPolicy = () => {
    return (
        <div className='PrivacyPolicyContainer'>
            <h1>
                Crowdcash Privacy Policy
            </h1>
            <h3>
                Effective Date: {EFFECTIVE_DATE}
            </h3>

            <p>
                At CrowdCash, we take privacy and security seriously. This Privacy Policy (the "Privacy Policy") outlines how Live Oak Financial LLC, 
                and its affiliates (collectively, “CrowdCash,” “we,” “our,” or “us”) process the information we collect 
                about you through our websites, mobile apps, and other online services (collectively, the “Services”) and when you otherwise interact 
                with us, such as through our customer service channels.
            </p>
            {/* <p>
                If you are a California resident, please also see the “Additional US State Privacy Rights” section below. 
                If you have a Crowdcash account, or have taken steps to register for an account, you can also review the 
                Crowdcash Financial and Crowdcash Securities RHF and RHS Financial Privacy Notice; 
                Crowdcash Money RHY Financial Privacy Notice; and Crowdcash Credit RCT Financial Privacy Notice for a summary of our practices 
                regarding the personally identifiable financial information we collect about you in connection with each of those services.
            </p> */}
            <h2>
                INFORMATION WE COLLECT AND HOW WE COLLECT IT.
            </h2>
            <h3>
                Information You Provide
            </h3>
            <p>
                We collect information you provide when you use, or apply to use, our Services or otherwise engage or communicate 
                with us as described below.
            </p>
            <p>
                Identity Data, such as your name, date of birth, marital status, social security number, photos of your face and 
                government-issued identification documents, and other data on government-issued identification documents;
            </p>
            <p>
                Contact Data, such as your email address, mailing address, and telephone number;
            </p>
            <p>
                Financial Data, such as your bank account and payment card details, and information about your income, account balances, 
                financial transaction history, credit history, tax information, and credit scores;
            </p>
            <p>
                Profile Data, such as your username and password, your interests, preferences, 
                feedback, and survey responses;
            </p>
            <p>
                Additional Data You Provide, such as via focus groups, survey responses, contests/sweepstakes, customer support, 
                data you provide in memos when using our Pay & Request Service, or other means.
            </p>
            <h3>
                Information We Collect Automatically or Generate
            </h3>
            <p>
                As is true of many digital platforms, we also collect certain information about you automatically when you use our Services, as described below.
            </p>
            <p>
                Usage Information. We collect information about your activity on our Services, which includes device identifiers 
                (like IP address or mobile device identifiers), pages or features you use, time and date of access, and other similar usage information.
            </p>
            <p>
                Transactional Information. When you receive, submit, or complete a transaction via the Services, we collect information about the 
                transaction, such as transaction amount, type and nature of the transaction, and time and date of the transaction.
            </p>
            <p>
                Information Collected Through Tracking Technologies. We and our service providers also use technologies, including cookies and web beacons, 
                to automatically collect certain types of usage and device information when you use our Services or interact with our emails. The 
                information collected through these technologies includes your IP address, browser type, internet service provider, platform type, 
                device type, operating system, date and time stamp, a unique device or account ID, usage information and other similar information. 
                For information about how to disable cookies, please see the Your Controls section below.
            </p>
            <h3>
                Information We Collect from Other Sources
            </h3>
            <p>
                We also obtain information about you from other sources as described below.
            </p>
            <p>
                Vendors and Business Partners. We collect information about you from companies that we do business with, such as name, contact data, 
                inferences about your preferences and attributes, as well as inferred fraud risk, from identity verification and fraud prevention partners.
            </p>
            <p>
                Plaid Services. We use Plaid Inc. (“Plaid”) to gather your data from financial institutions. By using the Services, you grant CrowdCash and Plaid the right, 
                power, and authority to access and transmit your personal and financial information from the relevant financial institution(s). The information 
                gathered by Plaid may include account balances, transaction history, and other financial data. This data collection is governed by <a href="https://plaid.com/legal/#end-user-privacy-policy">Plaid’s Privacy Policy.</a>
            </p>
            <p>
                Connected Services. If you link, connect, or log in to your Crowdcash Account with a third-party service (e.g., Google, Apple, Plaid), 
                the third-party service may send us information such as your profile information from that service. This information varies and is 
                controlled by that service or as authorized by you via your privacy settings at that service.
            </p>
            <p>
                Publicly Available Data. This may include include contact information, your interactions with our social media platforms, 
                and other information from publicly available sources, such as public websites.
            </p>
            <p>
                Advertising Data. We collect information in connection with our ad campaigns that surface on other platforms, 
                such as the ads you click on and other interactions with our ads.
            </p>
            <p>
                Credit Information. We may receive credit-related information about you from third parties, such as credit bureaus. 
                For example, if you apply for a loan with Crowdcash, we may collect your credit history and credit scores from credit 
                bureaus and other partners.
            </p>
            <h2>
                HOW WE USE YOUR INFORMATION.
            </h2>
            <p>
                We use the information we collect for purposes described below or as otherwise described to you at the point of collection:
            </p>
            <p>
                Maintain and provide the Services, including to process account applications, authenticate your identity and verify your financial 
                information through Plaid, repair our Services, support, process and record transactional information, and handle billing and account management;
            </p>
            <p>
                Send you transactional or relationship information, including confirmations, invoices, technical notices, customer support responses, 
                software updates, security alerts, support and administrative messages, and information about your transactions;
            </p>
            <p>
                Communicate with you about offers and other things we think you will be interested in, such as newsletters, product announcements, 
                partner offerings, surveys, contests or sweepstakes, events or announcements;
            </p>
            <p>
                Monitor and improve our Services, including analyzing usage, research and development;
            </p>
            <p>
                Help protect the safety and security of our Services, business, and users, such as to investigate and help prevent fraud or other unlawful activity;
            </p>
            <p>
                Protect or exercise our legal rights or defend against legal claims, including to enforce and carry out contracts and agreements; and
            </p>
            <p>
                Comply with applicable laws and legal obligations, such as compliance obligations associated with being a regulated broker-dealer and 
                compliance obligations associated with being a money services business.
            </p>
            <h2>
                DISCLOSURES OF INFORMATION.
            </h2>
            <p>
                We are committed to maintaining your trust, and we want you to understand when and with whom we disclose information about you. 
                We disclose information about you in the instances described below.
            </p>
            <p>
                Authorized vendors. We disclose information about you with third-party vendors and service providers who perform services for us, 
                such as identification verification, fraud prevention, advertising, mailing services, tax and accounting services, contest fulfillment, 
                web hosting, and analytics services. For example, we use an identification verification service provider, Plaid, to help determine 
                whether a selfie you take matches the photo in your government-issued identification. The information collected from your photo 
                by our vendors for these purposes may include biometric data.
            </p>
            <p>
            For example, we use Plaid, an identification and financial data verification service provider, to retrieve and verify your financial information, 
            such as account balances, transaction history, and other necessary financial data. The information collected by Plaid from your financial institution 
            is governed by <a href="https://plaid.com/legal/#end-user-privacy-policy">Plaid’s Privacy Policy.</a>
            </p>
            <p>
                Banking and credit card partners. Where applicable to the product you use, we also disclose information about you to other entities, 
                such as banking partners, credit card issuers that provide our credit cards, and credit card rewards partners 
                (e.g., travel or shopping rewards partners) through which you can redeem rewards.
            </p>
            <p>
                Substantial corporate transactions. We may disclose information about you in connection with a substantial corporate transaction, 
                a merger, consolidation, reorganization, financing, change in control or acquisition of all or a portion of our business by a 
                third party, or in the unlikely event of a bankruptcy or similar proceeding.
            </p>
            <p>
                Legal purposes. We disclose information about you if we believe that disclosure is in accordance with, or required by, any applicable 
                law or legal process or to protect and defend the rights, interests, safety, and security of Crowdcash, our users, or the public.
            </p>
            <p>
                With your consent. We disclose information about you for any other purposes disclosed to you with your consent.
            </p>
            <p>
                We may also disclose information with others in an aggregated or otherwise de-identified form that does not reasonably identify you.
            </p>
            <h2>
                THIRD-PARTY TRACKING AND ONLINE ADVERTISING.
            </h2>
            <p>
                We use third-party advertising and analytics services to better understand your online activity and serve you targeted 
                advertisements. For example, we use Google Analytics and you can review the “How Google uses information from sites or 
                apps that use our services” linked here: http://www.google.com/policies/privacy/partners/ for information about how Google 
                processes the information it collects. These companies collect information about your use of our Services and other websites 
                and online services over time through cookies, device identifiers, or other tracking technologies. The information collected 
                includes your IP address, web browser, mobile network information, pages viewed, time spent, links clicked, and conversion 
                information. We and our third-party partners use this information to, among other things, analyze and track data, determine 
                the popularity of content, and deliver advertisements targeted to your interests on our Services and other platforms, as well 
                as to provide advertising-related services to us such as reporting, attribution, analytics, and market research.
            </p>
            <p>
                For more information about interest-based ads, including to learn about options for opting out of having your web browsing 
                information used for targeted advertising purposes, please visit www.aboutads.info/choices. You should also review your 
                mobile device settings and controls for features that allow you to opt out or opt in to having certain information collected 
                for behavioral advertising purposes.
            </p>
            <h2>
                DO NOT TRACK.
            </h2>
            <p>
                Some web browsers transmit “do-not-track” signals to websites. We currently don’t take action in response to these signals.
            </p>
            <h2>
                YOUR CONTROLS.
            </h2>
            <p>
                How to control your communications preferences. You can stop receiving promotional emails from us by clicking the 
                “unsubscribe” link in those emails. We may still send you service-related or other non-promotional communications, 
                such as account notifications, receipts, security notices and other transactional or relationship messages.
            </p>
            <p>
                Cookie controls. Many web browsers are set to accept cookies and similar tracking technologies by default. 
                If you prefer, you can set your browser to delete or reject these technologies. If you choose to delete or 
                reject these technologies, this could affect certain features of our Services. If you use a different device, 
                change browsers, or delete the opt-out cookies that contain your preferences, you may need to perform the opt-out task again.
            </p>
            <h2>
                ADDITIONAL US STATE PRIVACY RIGHTS
            </h2>
            <p>
                This section provides additional details about the personal information we collect about California and Virginia 
                residents and the rights afforded to them under the California Consumer Privacy Act (“CCPA”) and the Virginia 
                Consumer Data Protection Act (“VCDPA”). Please note that the CCPA does not apply to what is referred to as nonpublic 
                personal information collected by financial institutions (like Crowdcash), as that information is subject instead to 
                other financial privacy laws. Additionally, the VCDPA does not apply to any Crowdcash entities that are financial 
                institutions. As a result, the CCPA and VCDPA do not apply to most of the information that Crowdcash collects.
            </p>
            <p>
                Subject to certain limitations and exceptions, the CCPA and VCDPA provide California and Virginia residents, 
                respectively, with certain rights. Depending on the state in which you are a resident, you may have the right to:
            </p>
            <p>
                • Confirm whether Crowdcash processes your personal information;
            </p>
            <p>
                • Correct inaccurate personal information;
            </p>
            <p>
                • Request details (in a readily usable format) about the categories and specific elements of personal information we collect;
            </p>
            <p>
                • Delete your personal information;
            </p>
            <p>
                • Opt out of any sales, sharing, targeted advertising, or profiling for certain decisions (as these terms are defined in the CCPA and/or VCDPA); and
            </p>
            <p>
                • Not be discriminated against for exercising these rights.;
            </p>
            <p>
                We do not sell information about you to third parties. In order to help Crowdcash deliver advertising and 
                marketing on other platforms, we do allow third parties to collect information through our Services. Please 
                see the “Third-Party Tracking and Online Advertising” section above for more details, including choices available to you.
            </p>
            <p>
                We collect the following categories of personal information: identifiers (such as name, email address and IP address), 
                Internet or other electronic network activity information (such as engagement with promotional messages and ads), and 
                inferences (such as gender, based on your first name). For California residents, the preceding list reflects Crowdcash’s 
                processing in the past 12 months. For more details about the information we collect (including the categories of sources 
                of this information) as well as the purposes for processing, please see the “Information We Collect and How We Collect It” 
                section above. We disclose this information with the categories of third parties described in the “Disclosures of Information” 
                section above.
            </p>
            <p>
                California and Virginia residents may make a request to exercise rights under the CCPA and VCDPA, respectively, that 
                are not otherwise exempt under applicable law. If you are a Crowdcash app user, a request may be made by logging into 
                your Crowdcash account, navigating to the Security and Privacy menu, and selecting the option to either Download Personal 
                Data or Request Data Deletion. You may also request assistance by sending an email to {EMAIL_CONTACT}. We may verify 
                the request by asking you to provide information that matches information we have on file about you. You can also designate 
                an authorized agent to exercise these rights on your behalf, but we will require proof that the person is authorized to act 
                on your behalf and may also still ask you to verify your identity with us directly. If you are a Virginia resident and 
                Crowdcash is unable to satisfy your request to exercise a right available to you under the VCDPA, you are entitled to appeal 
                our decision by emailing {EMAIL_CONTACT}.
            </p>
            <h2>
                CHILDREN.
            </h2>
            <p>
                We do not knowingly collect or solicit any information from anyone under the age of 13 through our Services. 
                If we become aware that a child under 13 has provided us with personal information for any reason other than 
                being added as an authorized user by their parent or guardian, we will take reasonable steps to delete that 
                information. If you reasonably believe that we might have information from a child under 13, please contact 
                us at {EMAIL_CONTACT}.
            </p>
            <h2>
                TRANSFER OF INFORMATION.
            </h2>
            <p>
                Except for when you use our Services outside the United States (e.g., while traveling), our Services are currently 
                designed for use only in the United States. If you are using our Services from another jurisdiction, or if third-party 
                service providers like Plaid transfer data, your information may be processed in the United States or other countries 
                that may not provide levels of data protection that are equivalent to those of your home jurisdiction.
            </p>
            <h2>
                CHANGES TO THIS POLICY.
            </h2>
            <p>
                This Privacy Policy will evolve with time, and when we update it, we will revise the “Effective Date” above and post 
                the new Policy. In some cases, we provide additional notice (such as adding a statement to our website or sending 
                you a notification), including when we add new third-party services like Plaid. To stay informed of our privacy practices, 
                we recommend you review the Policy on a regular basis as you continue to use our Services.
            </p>
            <h2>
                HOW TO CONTACT US.
            </h2>
            <p>
                If you have any questions about this Privacy Policy, please contact us at {EMAIL_CONTACT}.
            </p>
        </div>
    );
}

export default PrivacyPolicy;
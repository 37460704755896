import React, { useEffect, useMemo, useRef, useState } from 'react';

import styles from './feature_hero.module.css';
import { ButtonCTA } from '../../../components/button/button.tsx'
import ToggleSwitch from '../../../components/toggle_input/toggle_switch.tsx';

const borrowerImages = [
    'assets/borrower_hero_images/financial_tools.webp',
];

const lenderImages = [
    // 'assets/lender_hero_images/secured_default.webp',
    // 'assets/lender_hero_images/get_started_1.webp',
    // 'assets/lender_hero_images/get_started_2.webp',
    'assets/lender_hero_images/make_your_money_work.webp',
    // 'assets/lender_hero_images/move_money_securely.webp', //this one is a weird size
];

const FinancialDashboardImages = [
    'assets/borrower_hero_images/financial_tools.webp',
];

const BorrowerPoints = [
    {
        title: "Tools For Success",
        description: "Loans through us come with a financial tracking tool designed to help you make great financial decisions."
    },
    {
        title: "Competitive Rates",
        description: "Rates With CrowdCash are better because we aren't a bank. We're a community of people helping eachother financially."
    },
    {
        title: "More Than a Credit Score",
        description: "We take your whole financial self into account: your income, bank statements, and current debts."
    },
    {
        title: "Ultra Flexible",
        description: "If it's 3 months, 24 months or anywhere in-between, pay over the exact number of months that make sense for your budget."
    },
];

const LenderPoints = [
    {
        title: "Defaults can be Secured",
        description: (
            <>
                <p>Get a complete refund<sup>*</sup> your portion of the loan's principal if a Borrower defaults before making any payments.</p>
                <p><small>*refund available once per year per investor across all investments.</small></p>
            </>
        )
    },
    {
        title: "Simple and Effective Criteria",
        description: "Manually or automatically fund loans, or portions of loans based on: Risk Score, Loan term, and Loan Amount."
    },
    {
        title: "Using The Best Tech",
        description: "Funds move securely, directly between everyone's accounts. You get your account's savings rate if you don't lend any money."
    },
    {
        title: "Get started with as little as $50",
        // https://www.calculator.net/amortization-calculator.html?cloanamount=50&cloanterm=0&cloantermmonth=12&cinterestrate=18&cstartmonth=12&cstartyear=2024&cexma=0&cexmsm=12&cexmsy=2024&cexya=0&cexysm=12&cexysy=2024&cexoa=0&cexosm=12&cexosy=2024&caot=0&xa1=0&xm1=12&xy1=2024&xa2=0&xm2=12&xy2=2024&xa3=0&xm3=12&xy3=2024&xa4=0&xm4=12&xy4=2024&xa5=0&xm5=12&xy5=2024&xa6=0&xm6=12&xy6=2024&xa7=0&xm7=12&xy7=2024&xa8=0&xm8=12&xy8=2024&xa9=0&xm9=12&xy9=2024&xa10=0&xm10=12&xy10=2024&printit=0&x=Calculate#calresult
        // https://www.calculator.net/savings-calculator.html?cstartingprinciple=50&cannualaddition=0&cannualadditionincrease=0&cmonthlyaddition=0&cmonthlyadditionincrease=0&cinterestrate=1.185&ccompound=monthly&cyears=1&ctaxtrate=0&printit=0&x=Calculate#savingresult
        description: (
            <>
                <p>Given a <strong>18% APY</strong> loan over <strong>12 months</strong> and a default risk of (<strong>1.8%</strong><sup>1</sup>)</p>
                <p>After Crowdcash's service fee, a $50 loan nets you <strong>$53.36</strong>.<sup>3</sup></p>
                <p>Here's the <strong>proof:</strong></p>
                <p>($50 + 5.01) - ($50 x 0.018<sup>2</sup>) - ($5.01 x 0.15) = <strong>$53.36</strong>.<sup>3</sup></p>
                <p>For Comparison:</p>
                <ul>
                    <li><p>$50 in the national average savings would net $50.21<sup>4</sup></p></li>
                    <li><p>$50 in S&P 500 dividends would net $50.60<sup>5</sup></p></li>
                </ul>
                <p>
                    <small>
                        <sup>1</sup>default risk and rates may vary. 
                        <sup>2</sup>risk-adjusted returns may vary. 
                        <sup>3</sup>results shown are for illustrative purposes only.
                        <sup>4</sup>accurate as of 12/16/2024 from the FDIC National Rates and Rate Caps for Savings deposit products. 
                        <sup>5</sup>accurate as of 12/16/2024 from Gurufocus S&P 500 Dividend Yield.
                    </small>
                </p>
            </>
        )
    }
];

const FinancialDashboardPoints = [
    {
        title: "Better Budgeting",
        description: "Categorized transaction summaries help see where your money is going. Quickly spot areas of overspending, track progress against budgets, and find opportunities to cut costs."
    },
    {
        title: "Consolidated View",
        description: "Don't log into multiple bank accounts. Instead, get a single view of your entire financial picture, including balances, transactions, and spending categories."
    },
    {
        title: "Improved Decision Making",
        description: "With all your financial data at hand, you can make better decisions about how to allocate your resources, adjust your budget, or plan for future expenses."
    },
    {
        title: "Perfectly Low Price",
        description: "Powerful and real time tracking at only $1.67 per connected account per month."
    }
]

const FeaturePoints = ({points}: {points: {title: string, description: string | JSX.Element}[]}) => {
    const refs = useRef(points.map(() => React.createRef()));
    const [visibleIndices, setVisibleIndices] = useState<number[]>([]);

    useEffect(() => {
        const observers = refs.current.map((_ref: any, index: number) => {
            return new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            // Add index to visibleIndices if it becomes visible
                            setTimeout(() => {
                                setVisibleIndices((prevVisibleIndices) => {
                                    // Ensure we are not duplicating indices in the array
                                    if (!prevVisibleIndices.includes(index)) {
                                        return [...prevVisibleIndices, index];
                                    }
                                    return prevVisibleIndices;
                                });
                            }, index * 100); // Adds a delay for each group in sequence
                        } else {
                            // Remove index from visibleIndices if it becomes invisible
                            setVisibleIndices((prevVisibleIndices) =>
                                prevVisibleIndices.filter((i) => i !== index)
                            );
                        }
                    });
                },
                { threshold: 0.2 }
            );
        });

        refs.current.forEach((ref: { current: any; }, index: number) => {
            if (ref.current) observers[index].observe(ref.current);
        });

        return () => {
            refs.current.forEach((ref: { current: any; }, index: number) => {
                if (ref.current) observers[index].disconnect(ref.current);
            });
        };
    }, []);

    return (
        <>
            {points.map((point, index: number) => (
                <div
                    ref={refs.current[index]}
                    key={index}
                    className={`${styles.pointGroup} ${visibleIndices.includes(index) ? styles.fadeIn : styles.fadeOut}`}
                >
                    <h3>{point.title}</h3>
                    <p>{point.description}</p>
                </div>
            ))}
        </>
    );
};

const FeatureSection = ({buttonText, onClick, images, imagePlacement, featurePoints}) => {
    const detailsRef = useRef(null);
    const buttonRef = useRef(null);

    const [detailsVisible, setDetailsVisible] = useState(true);
    const [buttonVisible, setButtonVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === detailsRef.current) {
                            setDetailsVisible(true);
                        } else if (entry.target === buttonRef.current) {
                            setButtonVisible(true);
                        }
                    } else if(!entry.isIntersecting) {
                        if (entry.target === detailsRef.current) {
                            setDetailsVisible(false);
                        } else if (entry.target === buttonRef.current) {
                            setButtonVisible(false);
                        }
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (detailsRef.current) observer.observe(detailsRef.current);
        if (buttonRef.current) observer.observe(buttonRef.current);

        return () => {
            if (detailsRef.current) observer.unobserve(detailsRef.current);
            if (buttonRef.current) observer.unobserve(buttonRef.current);
        };
    }, []);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    return (
        <>
            <div ref={detailsRef} className={`${styles.DetailsContainer} ${detailsVisible ? styles.fadeIn : styles.fadeOut}`}>
                {imagePlacement === "top" ? <img src={images[currentImageIndex]} alt=''/> : null}
                <div className={styles.PointsContainer}>
                    {featurePoints}
                </div>
                {imagePlacement === "bot" ? <img src={images[currentImageIndex]} alt=''/> : null}
            </div>
            <div ref={buttonRef} className={`${buttonVisible ? styles.fadeIn : styles.fadeOut}`}>
                <ButtonCTA onClick={onClick}>{buttonText}</ButtonCTA>
            </div>
        </>
    );
}

const FeatureHero = ({takeOutLoanClick, becomeLenderClick, financialDashboardClick}: {takeOutLoanClick: ()=>void, becomeLenderClick: ()=>void, financialDashboardClick: ()=>void})=> {

    const headingRef = useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const toggleRefs = [useRef(null), useRef(null), useRef(null)];
  
    const [headingVisible, setHeadingVisible] = useState(false);
    const [toggleVisible, setToggleVisible] = useState([false, false, false]);
    const [sectionMapping, setSectionMapping] = useState({
        0: 0, // Section 0 shows "Take Out a Loan"
        1: 1, // Section 1 shows "Become a Lender"
        2: 2, // Section 2 shows "Track Your Finances"
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === headingRef.current) {
                            setHeadingVisible(true);
                        }
                    } else if(!entry.isIntersecting) {
                        if (entry.target === headingRef.current) {
                            setHeadingVisible(false);
                        }
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (headingRef.current) observer.observe(headingRef.current);

        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
        };
    }, []);

    useEffect(() => {
        const observers = toggleRefs.map((ref, index) =>
            new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.target === ref.current) {
                            setToggleVisible((prev) => {
                                const updated = [...prev];
                                updated[index] = entry.isIntersecting;
                                return updated;
                            });
                        }
                    });
                },
                { threshold: 0.3 }
            )
        );

        toggleRefs.forEach((ref, index) => {
            if (ref.current) observers[index].observe(ref.current);
        });

        return () => {
            toggleRefs.forEach((ref, index) => {
                if (ref.current) observers[index].unobserve(ref.current);
            });
        };
    }, [toggleRefs]);

    const handleSwitchChange = (sectionIndex: number, newOption: number) => {
        // Reverse the current mapping (option -> section)
        const invertedMapping = Object.fromEntries(
            Object.entries(sectionMapping).map(([section, option]) => [option, section])
        );

        // Find the section currently showing the newOption
        const currentSectionWithOption = invertedMapping[newOption];

        setSectionMapping((prevMapping) => ({
            ...prevMapping,
            [sectionIndex]: newOption, // Update the current section with the new option
            [currentSectionWithOption]: prevMapping[sectionIndex], // Swap the other section to show the old option
        }));
    };

    const sections = [
        <FeatureSection
            key={0}
            buttonText={"Take out a Loan"}
            onClick={takeOutLoanClick}
            imagePlacement="top"
            images={borrowerImages}
            featurePoints={<FeaturePoints points={BorrowerPoints} />}
        />,
        <FeatureSection
            key={1}
            buttonText={"Become a Lender"}
            onClick={becomeLenderClick}
            imagePlacement="bot"
            images={lenderImages}
            featurePoints={<FeaturePoints points={LenderPoints} />}
        />,
        <FeatureSection
            key={2}
            buttonText={"Track your Finances"}
            onClick={financialDashboardClick}
            imagePlacement="top"
            images={FinancialDashboardImages}
            featurePoints={<FeaturePoints points={FinancialDashboardPoints} />}
        />,
    ];

    const taglines = [
        "and explore how we can help you achieve your goals with peer-to-peer finance.",
        "and diversify your investments with innovative peer-to-peer opportunities.",
        "and take control of your financial journey with tools tailored to your needs.",
    ];

    return (
        <div className={styles.FeatureHeroContainer}>
            <div ref={headingRef} className={`${styles.HeadingContainer} ${headingVisible ? styles.fadeIn : styles.fadeOut}`}>
                <h4>Key Features</h4>
                <h1>Empowering You to:</h1>
            </div>
            {/* Render all three sections */}
            {Object.entries(sectionMapping).map(([sectionIndex, option]) => (
                <div key={sectionIndex} className={styles.FeatureSectionContainer}>
                    <div ref={toggleRefs[sectionIndex]} className={`${styles.ToggleContainer} ${toggleVisible[sectionIndex] ? styles.fadeIn : styles.fadeOut}`}>
                        <ToggleSwitch
                            value={option}
                            onChange={(newOption) =>
                                handleSwitchChange(parseInt(sectionIndex), newOption)
                            }
                            direction="horizontal"
                        />
                        <h3>{taglines[option]}</h3>
                    </div>
                    {sections[option]}
                </div>
            ))}
        </div>
    );
}

export default FeatureHero;
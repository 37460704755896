import './footer.css';
import React from 'react';

// import { DISCLAIMER_TEXT } from '../pages/disclaimer/disclaimer';

const Footer: React.FC = () => {
    return (
        <footer className="App-footer">
            <div className='Footer-columns'>
                <div className="Footer-column">
                    Site Map
                    <div className="Pages">
                        <a href="/" className="Link">Home</a>
                    </div>

                    {/* <div className="Pages">
                        <a href="/loan_application" className="Link">Loan Application</a>
                    </div>
                    <div className="Pages">
                        <a href="/manage_loan" className="Link">Manage Loans</a>
                    </div>
                    <div className="Pages">
                        <a href="/lend" className="Link">Lend</a>
                    </div> */}
                    <div className="Pages">
                        <a href="/dashboard" className="Link">Dashboard</a>
                    </div>
                    <div className="Pages">
                        <a href="/account_management" className="Link">Account</a>
                    </div>
                    {/* <div className="Pages">
                        <a href="/survey" className="Link">Survey</a>
                    </div> */}
                    <div className="Pages">
                        <a href="/faq" className="Link">FAQ</a>
                    </div>
                    <div className="Pages">
                        <a href="/privacy_policy" className="Link">Privacy</a>
                    </div>
                    <div className="Pages">
                        <a href="/terms_of_use" className="Link">Terms of Use</a>
                    </div>
                    <div className="Pages">
                        <a href="/disclosures" className="Link">Disclosures</a>
                    </div>
                </div>
                <div className="Footer-column">
                    Contact
                    <div className="Pages">
                        <p className="Link">contact@live-oak-financial.com</p>
                        {/* Memceh-qytzym-zehgi6 */}
                    </div>
                </div>
            </div>
            <div className="DiscloserContainer">
                <p>
                    <sup>A </sup>
                    Eligibility for personal loans up to $5,000 depends on the information provided by the applicant in the application form. 
                    Eligibility for personal loans is not guaranteed, and requires that a sufficient number of investors commit funds to your account 
                    and that you meet credit and other conditions. Refer to Borrower Registration Agreement for details and all terms and conditions. 
                    All personal loans made by Live Oak Financial LLC.
                </p>
                <p>
                    All lending and securities-related activities are conducted through Live Oak Financial LLC.
                    By accessing this site and any pages thereof, you agree to be bound by our User Agreement and Privacy Policy. 
                    Live Oak Financial LLC does not provide investment advice or make investment recommendations. 
                    No communication, through this website or in any other medium, should be construed as a recommendation for any security offering on or off this platform.
                </p>
                <p>
                    Regulation A+ Tier 1 offerings on this site are available to both accredited and non-accredited investors subject to certain investment limits. 
                    These offerings involve significant risks, including the potential loss of the entire investment. 
                    Securities sold through Regulation A+ offerings are not publicly traded and are intended for investors who do not require a liquid investment.
                    There can be no assurance that the valuation is accurate or in agreement with market or industry valuations. 
                    Investors will receive restricted stock subject to holding period requirements, which may be lengthy. 
                    Investing in Regulation A+ offerings requires a high tolerance for risk, low liquidity concerns, and long-term commitments. 
                    Only invest amounts of money you can afford to lose without altering your lifestyle.
                    Neither the Securities and Exchange Commission nor any federal or state securities commission or regulatory authority has recommended or approved any investment or the accuracy or completeness of any information or materials provided by or through the website. 
                    Investors must be able to afford the loss of their entire investment.
                </p>
                <p>
                    Investing in Regulation A+ offerings is inherently risky. These transactions involve borrower payment dependent notes and not equity in a company. 
                    The risk of loss is significant, and securities sold are not publicly traded, leading to low liquidity. 
                    Valuations may not align with market or industry standards. Restricted stock received by investors will be subject to holding periods.
                    Investing requires high-risk tolerance, long-term commitment, and the ability to withstand potential losses without financial hardship. 
                    Ensure that you invest only what you can afford to lose.
                </p>
            </div>
        </footer>
    );
}

export default Footer;